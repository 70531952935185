@import 'src/design-system/styles/typography.mixins';

.changeBtn {
  @include font-style-default;
  color: var(--text-link);
  text-decoration: none;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;

  &.withDivider {
    position: relative;
    padding-left: 10px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 15px;
      background: var(--shape-50);
      transform: translateY(-50%);
    }
  }

  &:hover {
    color: var(--text-primary);
  }

  &:active {
    color: var(--text-link);
  }

  &:disabled {
    color: var(--text-40);
    cursor: not-allowed;
  }
}
